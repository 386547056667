import { environmentOM4ABase } from '@env/environment.om4a'

export const environmentOM4ADev = {
  ...environmentOM4ABase,
  apiUrl: 'https://api.openmod4africadev.hevs.ch/api',
  geoserverUrl: 'https://geoserver.openmod4africadev.hevs.ch/geoserver/wms',
  keycloakUrl: 'https://auth.openmod4africadev.hevs.ch',
  matomoTrackerUrl: '',
  matomoSiteId: '',
  maintenanceNotice: '', // Set to '' if no maintenance is scheduled or the dates of the maintenance eg. '2025-01-20 - 2025-01-21' FORMAT IS IMPORTANT, end date is included
}

export const environment = environmentOM4ADev
